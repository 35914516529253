import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Mail, Phone } from 'lucide-react';
import mordyImage from './Images/mordy.jpg'; // Ensure this is high-resolution
import jeffImage from './Images/jeff.jpg'; // Ensure this is high-resolution

const leaders = [
  {
    name: 'Mordy Itzkowitz',
    title: 'Principal and Managing Director',
    image: mordyImage,
    description: `Mordy oversees all aspects of Rocky Company's property acquisition and development functions. 
      Since 2015, he has successfully completed complex redevelopment projects including urban infill locations 
      for Dollar Tree, Dollar General, and Starbucks. These projects were wholesale conversions requiring creative 
      reimagining of existing sites. Mordy possesses a keen ability to analyze complex challenges, identify innovative 
      solutions, and see them to fruition. His success can be attributed to exceptional communication skills that enable 
      him to forge productive relationships with stakeholders and vendors. His hands-on approach 
      has produced a track record of success in completing projects on time and within budget. Prior to his role with 
      the Rocky Companies, Mordy enjoyed over 25 years of success representing corporate tenants and landlords in all 
      aspects of commercial property brokerage and draws on his marketplace knowledge and experience to successfully 
      manage relationships with 3rd party contractors, property owners, and tenants.`,
    contact: 'mordy@rockycompanies.com | +1 (443) 838-1438'
  },
  {
    name: 'Jeffrey Gelberman',
    title: 'Principal and COO',
    image: jeffImage,
    description: `Jeff manages all financial activity and oversees property management for the Rocky Companies. He has more than 25 years of experience managing retail shopping centers. Jeff has successfully managed the Rocky Company's properties since inception and overseen the extensive rehab of more than 1 million square feet of the portfolio's retail centers. Jeff is detail-oriented, conservative in his approach to capital and investment management, and excels in his relationships with lenders, brokers, and tenants. He has successfully spearheaded leasing assignments for the Rocky Company's portfolio, which currently enjoys a 95% occupancy rate. Prior to his role with the Rocky Companies, Jeff had a successful career representing both landlords and tenants in every aspect of commercial retail brokerage for over 20 years.`,
    contact: 'jeff@rockycompanies.com | +1 (301) 529-9441'
  }
];

const LeaderCard = ({ leader, index }) => {
  const descriptionControls = useAnimation();
  const [descriptionRef, descriptionInView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  
  const [showPhone, setShowPhone] = useState(false);

  useEffect(() => {
    if (descriptionInView) {
      descriptionControls.start('visible');
    } else {
      descriptionControls.start('hidden');
    }
  }, [descriptionControls, descriptionInView]);

  return (
    <motion.div
      className={`flex flex-col md:flex-row ${index % 2 === 0 ? 'md:flex-row-reverse' : ''} items-center mb-12 bg-white shadow-lg rounded-lg overflow-hidden`}
    >
      <div className="md:w-1/2 flex justify-center p-6">
        <img
          src={leader.image}
          alt={leader.name}
          className="w-full h-auto object-contain shadow-lg border-4 border-white"
        />
      </div>
      <div className="md:w-1/2 p-6">
        <h3 className="text-2xl font-bold text-black">{leader.name}</h3>
        <h4 className="text-xl text-gray-700 mb-4">{leader.title}</h4>
        <motion.p
          ref={descriptionRef}
          initial="hidden"
          animate={descriptionControls}
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-gray-700 mb-4"
        >
          {leader.description}
        </motion.p>
        <div className="flex items-center mb-4">
          <button className="bg-[#EC1D26] text-white p-2 rounded-full mr-2">
            <a href={`mailto:${leader.contact.split(' | ')[0]}`}>
              <Mail className="w-5 h-5" />
            </a>
          </button>
          <button
            className="bg-[#EC1D26] text-white p-2 rounded-full"
            onMouseEnter={() => setShowPhone(true)}
            onMouseLeave={() => setShowPhone(false)}
            onClick={() => setShowPhone(!showPhone)}
          >
            {showPhone ? (
              <span className="text-white">{leader.contact.split(' | ')[1]}</span>
            ) : (
              <Phone className="w-5 h-5" />
            )}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const LeadershipSection = () => {
  return (
    <section id="leadership" className="py-20 bg-gray-100 text-black">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl font-bold mb-12 text-center text-[#EC1D26]"
        >
          Leadership
        </motion.h2>
        {leaders.map((leader, index) => (
          <LeaderCard key={index} leader={leader} index={index} />
        ))}
      </div>
    </section>
  );
};

export default LeadershipSection;
