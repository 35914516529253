import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import showcaseImage from './Images/mwhs.jpg';

const PortfolioShowcase = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <section className="relative w-full bg-black">
      <div className="container mx-auto px-4 py-20">
        <div className="relative w-full h-96 md:h-[32rem] lg:h-[40rem] bg-gray-100 rounded-lg shadow-lg">
          <motion.img
            src={showcaseImage}
            alt="Portfolio Showcase"
            className="w-full h-full object-cover rounded-lg"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />
          <motion.div
            ref={ref}
            className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-center rounded-lg"
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
            }}
          >
            <motion.h2
              className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
              }}
            >
              Explore Our Portfolio
            </motion.h2>
            <motion.p
              className="text-lg md:text-xl lg:text-2xl text-gray-300 mb-8"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
              }}
            >
              Explore the projects that define our success and dedication.
            </motion.p>
            <Link to="/portfolio">
              <motion.button
                className="px-6 py-3 bg-red-500 text-white rounded-full font-semibold hover:bg-red-600 transition duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
                }}
              >
                View Portfolio
              </motion.button>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioShowcase;
