import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import PortfolioPage from './PortfolioPage';
import NavBar from './NavBar'; 
import './App.css'; 
import Footer from './Footer';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen overflow-x-hidden"> 
        <NavBar /> 
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
          </Routes>
        </main>
        <FooterDisplay />
      </div>
    </Router>
  );
}

function FooterDisplay() {
  const location = useLocation();

  // Do not show the footer on the portfolio page
  if (location.pathname === '/portfolio') {
    return null;
  }

  return <Footer />;
}

export default App;
