import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import logoImage from './Images/ROCKYCOMPANIESLOGO.png';
import 'tailwindcss/tailwind.css';

const NavBar = ({ activeSection }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition > 100 || location.pathname === '/portfolio');
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMobileMenuOpen(false); // Close the mobile menu after clicking
    }
  };

  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.nav
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -100 }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-80 shadow-md w-full"
          >
            <div className="container mx-auto px-4 py-2 flex justify-between items-center w-full">
              <Link to="/" className="flex items-center">
                <img src={logoImage} alt="The Rocky Companies Logo" className="h-10 w-auto" />
              </Link>
              {location.pathname === '/portfolio' ? (
                <Link to="/" className="text-white hover:text-gray-300 transition duration-300">
                  Home
                </Link>
              ) : (
                <>
                  <div className="hidden md:flex space-x-4">
                    <Link to="/portfolio" className={`text-white hover:text-gray-300 transition duration-300 ${activeSection === 'portfolio' ? 'text-red-500' : ''}`}>Portfolio</Link>
                    <button onClick={() => scrollToSection('our-story')} className={`text-white hover:text-gray-300 transition duration-300 ${activeSection === 'our-story' ? 'text-red-500' : ''}`}>Our Story</button>
                    <button onClick={() => scrollToSection('leadership')} className={`text-white hover:text-gray-300 transition duration-300 ${activeSection === 'leadership' ? 'text-red-500' : ''}`}>Leadership</button>
                    <button onClick={() => scrollToSection('for-lease')} className={`text-white hover:text-gray-300 transition duration-300 ${activeSection === 'for-lease' ? 'text-red-500' : ''}`}>For Lease</button>
                    <button onClick={() => scrollToSection('contact-us')} className={`text-white hover:text-gray-300 transition duration-300 ${activeSection === 'contact-us' ? 'text-red-500' : ''}`}>Contact Us</button>
                  </div>
                  <div className="md:hidden">
                    <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
                      {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                  </div>
                </>
              )}
            </div>
          </motion.nav>
        )}
      </AnimatePresence>

      {isMobileMenuOpen && location.pathname !== '/portfolio' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center"
        >
          <Link to="/portfolio" onClick={() => setIsMobileMenuOpen(false)} className={`text-white text-2xl mb-4 hover:text-gray-300 transition duration-300 ${activeSection === 'portfolio' ? 'text-red-500' : ''}`}>Portfolio</Link>
          <button onClick={() => scrollToSection('our-story')} className={`text-white text-2xl mb-4 hover:text-gray-300 transition duration-300 ${activeSection === 'our-story' ? 'text-red-500' : ''}`}>Our Story</button>
          <button onClick={() => scrollToSection('leadership')} className={`text-white text-2xl mb-4 hover:text-gray-300 transition duration-300 ${activeSection === 'leadership' ? 'text-red-500' : ''}`}>Leadership</button>
          <button onClick={() => scrollToSection('for-lease')} className={`text-white text-2xl mb-4 hover:text-gray-300 transition duration-300 ${activeSection === 'for-lease' ? 'text-red-500' : ''}`}>For Lease</button>
          <button onClick={() => scrollToSection('contact-us')} className={`text-white text-2xl mb-4 hover:text-gray-300 transition duration-300 ${activeSection === 'contact-us' ? 'text-red-500' : ''}`}>Contact Us</button>
          <button onClick={toggleMobileMenu} className="text-white text-2xl mt-4 hover:text-gray-300 transition duration-300">
            <X size={32} />
          </button>
        </motion.div>
      )}

      {isMobileMenuOpen && location.pathname === '/portfolio' && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center"
        >
          <Link to="/" onClick={() => setIsMobileMenuOpen(false)} className="text-white text-2xl mb-4 hover:text-gray-300 transition duration-300">
            Home
          </Link>
          <button onClick={toggleMobileMenu} className="text-white text-2xl mt-4 hover:text-gray-300 transition duration-300">
            <X size={32} />
          </button>
        </motion.div>
      )}
    </>
  );
};

export default NavBar;
