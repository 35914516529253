import React from 'react';
import { motion } from 'framer-motion';
import CollinsBefore from './Images/Collinsbefore.jpg';
import CollinsAfter from './Images/Collinsafter.jpg';
import DollarBefore from './Images/DollarTreeBefore.jpg';
import DollarAfter from './Images/DollarTreeAfter.jpg';
import StarbsBefore from './Images/starbsbefore.jpg'
import StarbsAfter from './Images/starbsimage.jpg'

const properties = [
  {
    id: 1,
    beforeImage: CollinsBefore, 
    afterImage: CollinsAfter,
    description: `Collins Avenue Shopping Center - Baltimore, MD
This 30,000 SF urban shopping center underwent a complete structural and full scale renovation by The Rocky Companies.
Purchased mostly vacant in 2019 after a neighborhood thrift store closed its doors after 60 years, the center needed new life.
Floor elevations in half of the shopping center were raised by almost two feet to bring the center to a single level grade. 
Load bearing walls were removed to allow for a contiguous floor space. 
The center is now fully leased by "Amazon proof" tenants.`
  },
  {
    id: 2,
    beforeImage: DollarBefore, 
    afterImage: DollarAfter,
    description: `5013 York Road -
    This urban infill Dollar Tree development by The Rocky Companies required the assemblage of 3 lots: a former church, a house,
    and a vacant 10,000 square foot corner retail store. The development was a creative reimagining of a city block that attracted Dollar Tree on a 10-year lease.  
    The project was a boon to the neighborhood, as it eliminated vacancy and blight and brought a needed general merchandiser to the area.
    The full-scale redevelopment took 6 months to complete.`
  },
  {
    id: 3,
    beforeImage: StarbsBefore, 
    afterImage:StarbsAfter,
    description: `Starbucks - The Rocky Companies purchased this vacant former bank branch in Pikesville, MD. 
The 3,200 square foot drive thru building required a complete redevelopment to bring the Pikesville community a much needed drive thru upscale coffee shop.  
In 2020 the property was delivered as a turnkey store to Starbucks giving Pikesville, Maryland its first and only drive-thru Starbucks location.`
  },
];

const PropertyShowcase = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h2 className="text-4xl font-bold text-center mb-8">Before and After Development</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {properties.map((property) => (
          <motion.div
            key={property.id}
            className="relative overflow-hidden rounded-lg shadow-lg bg-white"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <div className="relative w-full h-56 overflow-hidden">
              <img
                src={property.beforeImage}
                alt={`Before - ${property.description}`}
                className="absolute w-full h-full object-cover"
              />
              <img
                src={property.afterImage}
                alt={`After - ${property.description}`}
                className="absolute w-full h-full object-cover transition-opacity duration-500 hover:opacity-0"
              />
            </div>
            <div className="p-4">
              <p className="text-gray-700">{property.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PropertyShowcase;
