import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Mail, Phone, MapPin } from 'lucide-react';
import emailjs from '@emailjs/browser';
import 'tailwindcss/tailwind.css';

const ContactSection = ({ id }) => {
  const formControls = useAnimation();
  const contactControls = useAnimation();
  const [formRef, formInView] = useInView({
    threshold: 0.1,
  });
  const [contactRef, contactInView] = useInView({
    threshold: 0.1,
  });

  // State to manage success, error, and loading states
  const [messageStatus, setMessageStatus] = useState({ success: '', error: '' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (formInView) {
      formControls.start('visible');
    } else {
      formControls.start('hidden');
    }

    if (contactInView) {
      contactControls.start('visible');
    } else {
      contactControls.start('hidden');
    }
  }, [formControls, contactControls, formInView, contactInView]);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    setMessageStatus({ success: '', error: '' });

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    ).then((result) => {
        setMessageStatus({ success: 'Your message has been sent successfully!', error: '' });
        e.target.reset(); // Clear the form after successful submission
      }).catch((error) => {
        setMessageStatus({ success: '', error: 'Something went wrong, please try again later.' });
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <section id={id} className="py-20 bg-white text-black min-h-screen">
      <div className="container mx-auto px-4">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl font-bold mb-8 text-center text-[#EC1D26]"
        >
          Contact Us
        </motion.h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <motion.div
            ref={formRef}
            initial="hidden"
            animate={formControls}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            transition={{ duration: 1 }}
            className="bg-gray-100 p-8 rounded-lg shadow-md"
          >
            <h3 className="text-2xl font-semibold text-black mb-4">Get in Touch</h3>
            <p className="text-gray-700 mb-4">We would love to hear from you! Whether you have a question about our properties, services, or anything else, our team is ready to answer all your questions.</p>
            
            {/* Display success or error message */}
            {messageStatus.success && (
              <p className="text-green-600 font-bold mb-4">{messageStatus.success}</p>
            )}
            {messageStatus.error && (
              <p className="text-red-600 font-bold mb-4">{messageStatus.error}</p>
            )}

            <form className="space-y-4" onSubmit={sendEmail}>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="name">Name</label>
                <input type="text" id="name" name="name" className="w-full p-3 bg-white text-black rounded-lg border border-gray-300 focus:outline-none focus:border-[#EC1D26]" />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                <input type="email" id="email" name="email" className="w-full p-3 bg-white text-black rounded-lg border border-gray-300 focus:outline-none focus:border-[#EC1D26]" />
              </div>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="message">Message</label>
                <textarea id="message" name="message" rows="4" className="w-full p-3 bg-white text-black rounded-lg border border-gray-300 focus:outline-none focus:border-[#EC1D26]"></textarea>
              </div>
              <button
                type="submit"
                className={`w-full p-3 bg-[#EC1D26] text-white rounded-lg font-semibold transition-colors ${loading ? 'cursor-not-allowed opacity-50' : 'hover:bg-[#d81b22]'}`}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </motion.div>

          {/* Contact Information */}
          <motion.div
            ref={contactRef}
            initial="hidden"
            animate={contactControls}
            variants={{
              hidden: { opacity: 0, x: 20 },
              visible: { opacity: 1, x: 0 },
            }}
            transition={{ duration: 1 }}
            className="space-y-6 flex flex-col justify-center"
          >
            <motion.div 
              className="bg-white rounded-lg shadow-md p-6 flex items-start space-x-4"
              whileHover={{ scale: 1.03 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="bg-[#EC1D26] p-3 rounded-full">
                <MapPin size={24} className="text-white" />
              </div>
              <div>
                <h4 className="text-xl font-semibold mb-2">Address</h4>
                <p className="text-gray-600">2833 Smith Avenue Suite 410, Pikesville, MD 21209</p>
              </div>
            </motion.div>
            <motion.div 
              className="bg-white rounded-lg shadow-md p-6 flex items-start space-x-4"
              whileHover={{ scale: 1.03 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="bg-[#EC1D26] p-3 rounded-full">
                <Phone size={24} className="text-white" />
              </div>
              <div>
                <h4 className="text-xl font-semibold mb-2">Phone</h4>
                <p className="text-gray-600">(410) 656-2230</p>
              </div>
            </motion.div>
            <motion.div 
              className="bg-white rounded-lg shadow-md p-6 flex items-start space-x-4"
              whileHover={{ scale: 1.03 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="bg-[#EC1D26] p-3 rounded-full">
                <Mail size={24} className="text-white" />
              </div>
              <div>
                <h4 className="text-xl font-semibold mb-2">Email</h4>
                <p className="text-gray-600">contact@rockycompanies.com</p>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
