import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ChevronDown } from 'lucide-react';
import logoImage from './Images/ROCKYCOMPANIESLOGO.png';
import backgroundVideo from './Images/hero.mp4';

const HeroSection = ({ id }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <section id={id} className="relative h-screen overflow-hidden">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute w-full h-full object-cover"
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center p-4"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
        }}
      >
        <motion.div
          className="flex flex-col items-center lg:flex-row lg:items-start max-w-5xl w-full"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
          }}
        >
          <img 
            src={logoImage}
            alt="The Rocky Companies Logo"
            className="w-32 h-auto mb-4 lg:w-48 lg:h-58 lg:mr-10 lg:ml-20 rounded-xl"
          />
          <div className="flex flex-col items-center lg:items-start text-center lg:text-left">
            <motion.h1
              className="text-2xl md:text-3xl lg:text-6xl font-bold text-gray-300"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
              }}
            >
              THE ROCKY COMPANIES
            </motion.h1>
            <motion.h2
              className="text-4xl md:text-5xl lg:text-6xl font-bold mt-2"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
              }}
            >
              Building Dreams
            </motion.h2>
            <motion.h3
              className="text-xl md:text-2xl lg:text-6xl mt-2"
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
              }}
            >
              One victory at a time.
            </motion.h3>
          </div>
        </motion.div>
        <motion.div
          className="mt-16 flex flex-col items-center"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0, transition: { delay: 1, duration: 1 } },
          }}
        >
          <span className="text-white text-xl font-semibold mb-4">Scroll Down</span>
          <ChevronDown size={80} className="text-white animate-bounce" />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default HeroSection;