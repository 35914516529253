import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingBag, Coffee, Stethoscope, Store, Briefcase, Building, Wrench } from 'lucide-react';

const icons = [
  ShoppingBag,
  Coffee,
  Stethoscope,
  Store,
  Briefcase,
  Building,
  Wrench,
];

const getRandomPosition = () => ({
  top: `${Math.random() * 100}%`,
  left: `${Math.random() * 100}%`,
});

const getRandomAnimation = () => ({
  y: [0, Math.random() * 50 - 25, 0],
  x: [0, Math.random() * 50 - 25, 0],
  opacity: [0, 1, 0],
});

const floatingIcons = icons.flatMap((Icon, index) =>
  Array(5).fill().map((_, i) => (
    <motion.div
      key={`${index}-${i}`}
      className="absolute"
      initial={{ y: 0, x: 0, opacity: 0 }}
      animate={getRandomAnimation()}
      transition={{
        duration: 6 + Math.random() * 4,
        repeat: Infinity,
        repeatType: 'mirror',
        ease: 'easeInOut',
      }}
      style={getRandomPosition()}
    >
      <Icon size={32} className="text-gray-400 opacity-50" />
    </motion.div>
  ))
);

const Footer = () => {
  return (
    <footer className="relative bg-black text-white py-12 overflow-hidden">
      {floatingIcons}
      <div className="relative container mx-auto px-4 z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-2xl font-bold mb-4">The Rocky Companies</h4>
            <p>Your trusted partner in real estate.</p>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4">Contact Us</h4>
            <p>2833 Smith Avenue Suite 410</p>
            <p>Pikesville, MD 21209</p>
            <p>Phone: (410) 656-2230</p>
            <p>Email: contact@rockycompanies.com</p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-800 text-center">
          <p>&copy; 2024 The Rocky Companies. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
