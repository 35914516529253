import React, { useEffect, useState } from 'react';
import HeroSection from './HeroSection';
import StatisticsSection from './StatisticsSection';
import PropertiesSection from './PropertiesSection';
import ContactSection from './ContactSection';
import LeadershipSection from './LeadershipSection';
import OurStory from './OurStory';
import PortfolioShowcase from './PortfolioSHowcase'
import PropertyShowcase from './PropertyShowcase';
import NavBar from './NavBar';

const LandingPage = () => {
  const [scrollY, setScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const sections = document.querySelectorAll('section');
      let currentSection = '';
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollY >= sectionTop - sectionHeight / 3) {
          currentSection = section.getAttribute('id');
        }
      });
      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollY]);

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash) {
      const section = document.getElementById(urlHash.replace('#', ''));
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <NavBar activeSection={activeSection} />
      <HeroSection id="hero" />
      <StatisticsSection id="our-impact" />
      <PortfolioShowcase id="portfolio-showcase" />
      <OurStory id="our-story" />
      <PropertyShowcase />
      <LeadershipSection id="leadership" />
      <PropertiesSection id="for-lease" />
      <ContactSection id="contact-us" />
    </div>
  );
};

export default LandingPage;
