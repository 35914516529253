import React from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Building, MapPin, Users, Store, Hammer } from 'lucide-react';
import CountUp from 'react-countup';

const statistics = [
  {
    icon: <Building size={48} className="text-red-600" />,
    stat: 2000000,
    description: (
      <span style={{ whiteSpace: 'nowrap' }}>
        Square Feet Owned and Managed
      </span>
    ),
    addPlus: true
  },
  {
    icon: <Users size={48} className="text-red-600" />,
    stat: 250,
    description: "Tenants Under Lease",
    addPlus: true
  },
  {
    icon: <Store size={48} className="text-red-600" />,
    stat: 50,
    description: "Years of Commercial Real Estate Experience",
    addPlus: true
  },
  {
    icon: <MapPin size={48} className="text-red-600" />,
    stat: 12,
    description: "Active U.S. Markets",
    addPlus: false
  },
  {
    icon: <Hammer size={48} className="text-red-600" />,
    stat: 10,
    description: "Development Projects Since 2018",
    addPlus: true
  }
];

const StatisticCard = ({ icon, stat, description, addPlus, index }) => {
  const controls = useAnimation();
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: false, margin: '-50px 0px' });

  React.useEffect(() => {
    if (isInView) {
      controls.start({
        opacity: 1,
        scale: [0.9, 1.05, 1],
        transition: { duration: 0.8, delay: index * 0.2 }
      });
    } else {
      controls.start({
        opacity: 0,
        scale: 0.9,
      });
    }
  }, [isInView, controls, index]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={controls}
      className="bg-black border-2 border-red-600 rounded-lg p-8 m-4 w-full md:w-1/3 lg:w-1/4 flex flex-col justify-center items-center transform transition-all duration-500 hover:shadow-lg hover:scale-105"
    >
      <div className="bg-black rounded-full p-4 mb-4">
        {icon}
      </div>
      <h3 className="text-3xl font-semibold text-white mt-4">
        <CountUp start={0} end={stat} duration={2} separator="," />
        {addPlus && '+'}
      </h3>
      <p className="text-gray-400 mt-2">{description}</p>
    </motion.div>
  );
};

const StatisticsSection = () => {
  return (
    <section id="our-impact" className="relative py-20 bg-black text-white">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl font-bold mb-12"
        >
          Our Impact
        </motion.h2>
        <div className="flex flex-wrap justify-center items-stretch">
          {statistics.map((item, index) => (
            <StatisticCard
              key={index}
              icon={item.icon}
              stat={item.stat}
              description={item.description}
              addPlus={item.addPlus}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
