import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import 'tailwindcss/tailwind.css';
import BackgroundStory from './Images/backgroundstory.jpg';

const OurStory = ({ id }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  return (
    <section id={id} className="relative bg-black text-white min-h-screen flex items-center justify-center">
      <div className="absolute inset-0 overflow-hidden bg-fixed bg-center bg-cover" style={{ backgroundImage: `url(${BackgroundStory})` }}>
        <div className="absolute inset-0 bg-black opacity-70"></div>
      </div>
      <div className="relative container mx-auto px-4 text-center">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={containerVariants}
          className="space-y-8"
        >
          <motion.h2
            variants={itemVariants}
            className="text-5xl font-extrabold mb-8"
          >
            Our Story
          </motion.h2>
          <motion.p
            variants={itemVariants}
            className="text-lg lg:text-2xl leading-relaxed max-w-3xl mx-auto mb-4"
          >
            The Rocky Companies is a Maryland based real estate investment and development firm owned by principal partners Mordy&nbsp;Itzkowitz&nbsp;and&nbsp;Jeffrey&nbsp;Gelberman.
          </motion.p>
          <motion.p
            variants={itemVariants}
            className="text-lg lg:text-2xl leading-relaxed max-w-3xl mx-auto mb-8"
          >
            Since 2015, the Rocky Companies have acquired, redeveloped, and fully leased more than a dozen commercial real estate projects in the Mid-Atlantic and Midwest regions totaling more than two million square feet of retail shopping centers and commercial properties. Through strategic investment and redevelopment efforts, the Rocky Companies have significantly contributed to the growth and revitalization of these areas.
          </motion.p>
          <motion.div
            variants={itemVariants}
            className="text-center mt-12"
          >
            {/* Placeholder for a future link/button */}
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default OurStory;
